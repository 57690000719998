import { CourseServiceType, ProjectServiceType } from "interfaces/front/business";
import { useMemo } from "react";

const useServiceType = (
  serviceType?: ProjectServiceType | CourseServiceType
): {
  isAboutHometownTax: boolean;
  isCorpHometownTax: boolean;
  isHometownTax: boolean;
  isShopping: boolean;
} => {
  const isCorpHometownTax = useMemo(() => serviceType === "corp_hometown_tax", [serviceType]);
  const isHometownTax = useMemo(() => serviceType === "hometown_tax", [serviceType]);
  const isShopping = useMemo(() => serviceType === "shopping", [serviceType]);
  const isAboutHometownTax = useMemo(() => isHometownTax || isCorpHometownTax, [isHometownTax, isCorpHometownTax]);
  return { isAboutHometownTax, isCorpHometownTax, isHometownTax, isShopping };
};
export default useServiceType;
