import React, { FC, HTMLAttributes, useMemo } from "react";
import { ProjectServiceType } from "interfaces/front/business";
import styled from "styled-components";
import useServiceType from "lib/hooks/front/useServiceType";

const ServiceTypeBadge: FC<HTMLAttributes<HTMLDivElement> & { serviceType: ProjectServiceType }> = ({ serviceType, ...props }) => {
  const { isAboutHometownTax, isHometownTax, isShopping } = useServiceType(serviceType);
  const logoImage = useMemo(() => {
    if (isShopping) {
      return "/img/shopping_logo.png"; 
    }
    return isAboutHometownTax
      ? isHometownTax
        ? "/img/hometown_tax_logo.png"
        : "/img/corp_hometown_tax_logo.png"
      : "/img/crowd_funding_logo.png";
  }, [isAboutHometownTax, isHometownTax, isShopping]);
  return (
    <Root {...props}>
      <Image src={logoImage} />
    </Root>
  );
};
export default ServiceTypeBadge;

const Root = styled.div`
  border: 1px solid #fff;
  display: inline-block;
  @media screen and (max-width: 960px) {
    padding: 4px 5px;
    line-height: 12px;
  }
  @media screen and (min-width: 961px) {
    padding: 6px 8px;
    line-height: 14px;
  }
`;
const Image = styled.img`
  vertical-align: unset; // style.cssが邪魔するので必要だった
  @media screen and (max-width: 960px) {
    height: 12px;
  }
  @media screen and (min-width: 961px) {
    height: 14px;
  }
`;
